import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import { GatsbySeo } from "gatsby-plugin-next-seo"
import VideosMenu from "../../components/videosMenu"

const TeCuentoUnaCosita = ({ data }) => {
  const videos = data.allYoutubeVideo.edges.map(x => (
    <div className="video-padding">
      <iframe
        title="youtube video"
        width="560"
        height="315"
        loading="lazy"
        src={`https://www.youtube-nocookie.com/embed/${x.node.videoId}`}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        className="lazyload"
      />
    </div>
  ))

  return (
    <Layout>
      <GatsbySeo
        title="Te Cuento Una Cosita - Videos"
        titleTemplate="%s | Eduardo Ceballos"
        description="Te Cuento Una Cosita - Videos"
        canonical="https://eduardoceballos.com/videos/te-cuento-una-cosita"
        openGraph={{
          url: `https://eduardoceballos.com/videos/te-cuento-una-cosita`,
          title: `Te Cuento Una Cosita - Videos`,
          description: `Te Cuento Una Cosita - Videos`,
          images: [
            {
              url: `https://eduardoceballos.com/seo/videos.jpg`,
              alt: `Te Cuento Una Cosita - Videos`,
            },
          ],
          site_name: "Eduardo Ceballos",
        }}
        twitter={{
          handle: "@PoetadeSalta",
          site: "@PoetadeSalta",
          cardType: "summary_large_image",
        }}
      />
      <h1>Videos - Te cuento una cosita</h1>
      <VideosMenu />
      <div className="videos-grid">{videos}</div>
    </Layout>
  )
}

export const query = graphql`
  {
    allYoutubeVideo {
      edges {
        node {
          title
          videoId
        }
      }
    }
  }
`

export default TeCuentoUnaCosita
