import React from "react"
import { Link } from "gatsby"

const VideosMenu = () => (
  <div>
    <div className="videos-menu-flex-1">
      <Link to="/videos/te-cuento-una-cosita">Te cuento una cosita</Link>
      <Link to="/videos/otros">Otros</Link>
    </div>
  </div>
)

export default VideosMenu
